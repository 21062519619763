import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, Typography, Container, Alert } from '@mui/material';
import { getUserExist, postRequest } from '../../api/axios';
import { useTranslation } from 'react-i18next';
import "./newAppUser.css";
const NewAppUser = () => {
    const { t } = useTranslation('global');

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [idError, setIdError] = useState('');
    const [succ, setSucc] = useState('');

    const checkUserExists = async () => {
        if (username) {
            try {
                const response = await getUserExist(username);
                if (response.success) {
                    setIdError(t("newAppUser.existingUser"));
                    return false;
                } else {
                    setIdError('');
                    return true;
                }
            } catch (error) {
                console.error(t("newAppUser.errorCheckingUser"), error);
                setIdError(t("newAppUser.errorCheckingUser"));
                return false;
            }
        }
        return false;
    };

    useEffect(() => {
        if (password !== confirmPassword) {
            setErrMsg(t("newAppUser.passwordDoesntMatch"));
        } else {
            setErrMsg('');
        }
    }, [confirmPassword]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (username === '' || password === '') {
            setErrMsg(t("newAppUser.allFieldsAreRequired"));
            return;
        }
        const userExists = await checkUserExists();
        if (!userExists) {
            return;
        }
        // Sending to backend
        const formData = new URLSearchParams();
        formData.append('username', username);
        formData.append('password', password);
        console.log(username, " ", password);
        const response = await postRequest("/users/add", formData);
        if (!response.success) {
            console.log(response.message);
        } else {
            setIdError('');
            setUsername('');
            setPassword('');
            setConfirmPassword('');
            setSucc(t("newAppUser.successfullyAdded"));
        }
    };

    return (
        <Container maxWidth="sm" style={{alignItems:"center", justifyContent:"center"}}>
            <Box
                sx={{
                    mt: 4,
                    p: 3,
                    borderRadius: 2,
                    boxShadow: 3,
                    backgroundColor: '#f9f9f9',
                }}
                className="container-user"
            >
                <Typography variant="h4" align="center" gutterBottom>
                    {t("newAppUser.newUser")}
                </Typography>
                <form onSubmit={handleSubmit} className="form">
                        <Box item xs={12}>
                            <TextField
                                fullWidth
                                id="username"
                                label={t("newAppUser.username")}
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                variant="outlined"
                            />
                        </Box>
                        <Box item xs={12}>
                            <TextField
                                fullWidth
                                type="password"
                                id="password"
                                label={t("newAppUser.password")}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                variant="outlined"
                            />
                        </Box>
                        <Box item xs={12}>
                            <TextField
                                fullWidth
                                type="password"
                                id="confirmPassword"
                                label={t("newAppUser.confirmPassword")}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                variant="outlined"
                            />
                        </Box>
                        {errMsg && (
                            <Box item xs={12}>
                                <Alert severity="error" onClose={() => setErrMsg('')}>
                                    {errMsg}
                                </Alert>
                            </Box>
                        )}
                        {idError && (
                            <Box item xs={12}>
                                <Alert severity="error" onClose={() => setIdError('')}>
                                    {idError}
                                </Alert>
                            </Box>
                        )}
                        {succ && (
                            <Box item xs={12}>
                                <Alert severity="success" onClose={() => setSucc('')}>
                                    {succ}
                                </Alert>
                            </Box>
                        )}
                        <Box item xs={12}>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                {t("newAppUser.add")}
                            </Button>
                        </Box>
                </form>
            </Box>
        </Container>
    );
};

export default NewAppUser;
