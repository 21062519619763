import React, {useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom';
import {getSoldTickets} from '../../api/axios'
import Table from '../Table/Table';
import { useTranslation } from 'react-i18next';

const SoldTickets = () => {
  const { t } = useTranslation('global');

  const [loading, setLoading] = useState(true);
  const [soldTickets, setSoldTickets] = useState([]);
  const location = useLocation();
  const { startDate, endDate } = location.state || {};

  useEffect(() => {
    const retreiveInfo = async () => {
        const response = await getSoldTickets(startDate, endDate);
        setSoldTickets(response);
        setLoading(false);
    };

    retreiveInfo();
  }, []);

  const column = [
    {heading: t("reports.category"), value: "category"},
    {heading: t("reports.kind"), value: "kind"},
    {heading: t("reports.type"), value: "type"},
    {heading: t("reports.price"), value: "price"},
    {heading: t("reports.ticketAgent"), value: "log_card"},
  ];

  return (
    <div>
      <h2>{t("reports.soldTickets")}</h2>
      { loading ? (<p>{t("reports.loading")}</p>) : (
        <Table data={soldTickets} column={column}/>
        
      ) }
    </div>
  )
}

export default SoldTickets
