import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper
} from '@mui/material';
import { getMonitoring } from '../../api/axios';
import { useTranslation } from 'react-i18next';

const Monitoring = () => {
  const { t } = useTranslation('global');

  const [events, setEvents] = useState([]);
  const [timestamp, setTimestamp] = useState(Date.now());
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredEvents, setFilteredEvents] = useState([]);

  const fetchData = async () => {
    try {
      const date = new Date();
      const year = date.getFullYear().toString().slice(-2);
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      const formattedDate = `20${year}-${month}-${day}`;

      const response = await getMonitoring(formattedDate, formattedDate);

      setEvents(response.slice(0, 15)); // Prikazujemo samo prvih 15 rezultata
    } catch (error) {
      console.error(t("monitoring.errorFetchingEvents"), error);
    }
  };

  useEffect(() => {
    // Prvo učitavanje podataka
    fetchData();

    // Ažuriranje podataka svakih sekundu
    const interval = setInterval(() => {
      fetchData();
      setTimestamp(Date.now()); // Ažuriramo timestamp kako bi komponenta ponovo renderovala
    }, 1000);

    // Očisti interval pri demontiranju komponente
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Filtriraj događaje na osnovu termina za pretragu
    if (Array.isArray(events)) {
      setFilteredEvents(
        events.filter(event =>
          Object.values(event).some(val =>
            val.toString().toLowerCase().includes(searchTerm.toLowerCase())
          )
        )
      );
    }
  }, [searchTerm, events]);

  return (
    <Box sx={{ padding: 3, maxWidth: 1200, margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom>
        {t("monitoring.monitor")}
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        {t("monitoring.lastUpdate")} {new Date(timestamp).toLocaleTimeString()}
      </Typography>
      <TextField
        label={t("monitoring.search")}
        variant="outlined"
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        fullWidth
        sx={{ marginBottom: 3 }}
      />
      {filteredEvents.length > 0 ? (
        <Paper elevation={3} sx={{ overflow: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("monitoring.ticket")}</TableCell>
                <TableCell>{t("monitoring.dateAndTime")}</TableCell>
                <TableCell>{t("monitoring.barrier")}</TableCell>
                <TableCell>{t("monitoring.result")}</TableCell>
                <TableCell>{t("monitoring.name")}</TableCell>
                <TableCell>{t("monitoring.lastName")}</TableCell>
                <TableCell>{t("monitoring.passesRemaining")}</TableCell>
                <TableCell>{t("monitoring.daysRemaining")}</TableCell>
                <TableCell>{t("monitoring.remainingToday")}</TableCell>
                <TableCell>{t("monitoring.expireDate")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredEvents.map((event, index) => (
                <TableRow
                  key={`${event.serial_num}-${index}`}
                  sx={{
                    backgroundColor:
                      event.reject === 'Success' ? 'rgba(76, 175, 80, 0.2)' : 'rgba(244, 67, 54, 0.2)'
                  }}
                >
                  <TableCell>{event.serial_num}</TableCell>
                  <TableCell>{event.datetime}</TableCell>
                  <TableCell>{event.mac_addr}</TableCell>
                  <TableCell>{event.reject}</TableCell>
                  <TableCell>{event.name}</TableCell>
                  <TableCell>{event.surname}</TableCell>
                  <TableCell>{event.remaining_passes}</TableCell>
                  <TableCell>{event.remaining_days}</TableCell>
                  <TableCell>{event.remaining_daily}</TableCell>
                  <TableCell>{event.exceed_date}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      ) : (
        <Typography variant="body1">{t("monitoring.empty")}</Typography>
      )}
    </Box>
  );
};

export default Monitoring;

