import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { getPassedTickets } from '../../api/axios'
import Table from '../Table/Table';
import { useTranslation } from 'react-i18next';

const PassedTickets = () => {
  const { t } = useTranslation('global');

  const [loading, setLoading] = useState(true);
  const [passedTickets, setPassedTickets] = useState([]);
  const location = useLocation();
  const { startDate, endDate } = location.state || {};

  useEffect(() => {
    const retreiveInfo = async () => {
      try {
        const response = await getPassedTickets(startDate, endDate);
        
        setPassedTickets(response);
      } catch (error) {
        console.error(t("reports.errorFetchingData"), error);
      } finally {
        setLoading(false);
      }
    };

    retreiveInfo();
  }, []);

  const column = [
    { heading: t("reports.ticket"), value: "serial_num" },
    { heading: t("reports.dateAndTime"), value: "datetime" },
    { heading: t("reports.barrier"), value: "reader" },
    { heading: t("reports.result"), value: "message" },
    { heading: t("reports.name"), value: "name" },
    { heading: t("reports.lastName"), value: "surname" },
    { heading: t("reports.phoneNumber"), value: "phone" },
  ];

  return (
    <div>
      <h2>{t("reports.passedTickets")}</h2>
      {loading ? (<p>{t("reports.loading")}</p>) : (
        <Table data={passedTickets} column={column} />

      )}
    </div>
  )
}

export default PassedTickets
