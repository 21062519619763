import React from 'react'
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t } = useTranslation('global');

  return (
    <div>
      <h1>{t("home.welcomeToHomepage")}</h1>
    </div>
  )
}

export default Home
