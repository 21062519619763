import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { TextField, Button, Checkbox, FormControlLabel, FormGroup, Typography, Grid, Box } from '@mui/material';
import { getReaders, postRequest } from '../../api/axios';
import Error from '../Error/Error';
import Success from '../Success/Success';
import Notification from '../Notification/Notification'; // Import Notification component
import { getUsername } from '../../context/AuthFunc';
import { useTranslation } from 'react-i18next';
import "./newTicket.css";

const NewTicket = () => {
    const { t } = useTranslation('global');

    const [ticketId, setTicketId] = useState('');
    const [surname, setSurname] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [hFrom, setHFrom] = useState('07:00');
    const [hTo, setHTo] = useState('23:00');
    const [dayNo, setDayNo] = useState('30');
    const [entryNo, setEntryNo] = useState('30');
    const [dailyNo, setDailyNo] = useState('0');
    const [readers, setReaders] = useState([]);
    const [submitter, setSubmitter] = useState('');
    const [selectedReaders, setSelectedReaders] = useState([]);
    const [errMsg, setErrMsg] = useState('');
    const [succ, setSucc] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const fetchReaders = async () => {
            try {
                const response = await getReaders();
                setReaders(response); // Assuming the response contains a list of readers
                setSelectedReaders(response.map(reader => reader.id));
            } catch (error) {
                console.error(t("newTicket.errorFetchingReaders"), error);
            }
        };

        fetchReaders();
        setSubmitter(getUsername);
    }, []);
    
    const formatTime = (time) => {
        if (time.length === 5) { // Format H:i
            return `${time}:00`;
        }
        return time;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formattedHFrom = formatTime(hFrom);
        const formattedHTo = formatTime(hTo);

        if (!ticketId) {
            setErrMsg(t("newTicket.ticketNumberRequired"));
            return;
        } 
        if (selectedReaders.length === 0) {
            setSelectedReaders(readers.map(reader => reader.id));
        }

        const newTicket = {
            ticket_id: ticketId,
            surname,
            name,
            phone,
            h_from: formattedHFrom,
            h_to: formattedHTo,
            day_no: dayNo,
            entry_no: entryNo,
            daily_no: dailyNo,
            readers: selectedReaders.includes('all') ? readers.map(reader => reader.id) : selectedReaders,
            submitter: submitter
        };

        try {
            const response = await postRequest('/write-qr', new URLSearchParams(newTicket));
            if (!response.success) {
                setErrMsg(t("newTicket.somethingWentWrong"));
                setTimeout(() => setErrMsg(''), 3000);
            } else {
                setSucc(t("newTicket.successfullyAdded"));
                setTicketId('');
                setSurname('');
                setName('');
                setPhone('');
                setHFrom('07:00');
                setHTo('23:00');
                setDayNo('30');
                setEntryNo('30');
                setDailyNo('0');
                setSelectedReaders([]);
                setTimeout(() => setSucc(''), 3000);
            }
            setShowNotification(true);
        } catch (error) {
            setErrMsg(t("newTicket.failedToAddTicket"));
            setShowNotification(true);
        }
    };

    const handleReaderChange = (e) => {
        const value = e.target.value;
        if (value === 'all') {
            setSelectedReaders((prev) =>
                prev.includes('all') ? [] : readers.map(reader => reader.id)
            );
        } else {
            setSelectedReaders((prev) =>
                prev.includes(value)
                    ? prev.filter((reader) => reader !== value)
                    : [...prev.filter((reader) => reader !== 'all'), value]
            );
        }
    };

    return (
        <Box className="container-ticket" sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>{t("newTicket.newTicket")}</Typography>
            <form onSubmit={handleSubmit} style={{ display:'flex', flexDirection:'column' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t("newTicket.ticketSerialNumber")}
                            variant="outlined"
                            fullWidth
                            value={ticketId}
                            onChange={(e) => setTicketId(e.target.value)}
                            required
                            title={t("newTicket.readFromRFID")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t("newTicket.firstName")}
                            variant="outlined"
                            fullWidth
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t("newTicket.lastName")}
                            variant="outlined"
                            fullWidth
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t("newTicket.phoneNumber")}
                            variant="outlined"
                            fullWidth
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t("newTicket.entryFrom")}
                            variant="outlined"
                            type="time"
                            fullWidth
                            value={hFrom}
                            onChange={(e) => setHFrom(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t("newTicket.entryUntil")}
                            variant="outlined"
                            type="time"
                            fullWidth
                            value={hTo}
                            onChange={(e) => setHTo(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t("newTicket.numberOfDaysTheTicketIsValid")}
                            variant="outlined"
                            fullWidth
                            value={dayNo}
                            onChange={(e) => setDayNo(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t("newTicket.totalNumberOfEntries")}
                            variant="outlined"
                            fullWidth
                            value={entryNo}
                            onChange={(e) => setEntryNo(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t("newTicket.numberOfDailyEntries")}
                            variant="outlined"
                            fullWidth
                            value={dailyNo}
                            onChange={(e) => setDailyNo(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            onClick={() => setIsModalOpen(true)}
                        >
                            {t("newTicket.selectReader")}
                        </Button>
                    </Grid>
                </Grid>
                
                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={() => setIsModalOpen(false)}
                    contentLabel="Select Readers"
                    className="modal"
                    overlayClassName="overlay"
                >
                    <div className="modal-header">
                        <h2>{t("newTicket.selectReader")}</h2>
                        <button className="close-button" onClick={() => setIsModalOpen(false)}>×</button>
                    </div>
                    <form>
                        <div className='checkbox-container'>
                            <label className="reader-label">
                                <input
                                    type="checkbox"
                                    value="all"
                                    checked={selectedReaders.length === readers.length}
                                    onChange={handleReaderChange}
                                    className="reader-checkbox"
                                />
                                {t("newTicket.all")}
                            </label>
                        </div>
                        {readers.map(reader => (
                            <div key={reader.id} className='checkbox-container'>
                                <label className="reader-label">
                                    <input
                                        type="checkbox"
                                        value={reader.id}
                                        checked={selectedReaders.includes(reader.id)}
                                        onChange={handleReaderChange}
                                        className="reader-checkbox"
                                    />
                                    {reader.name}
                                </label>
                            </div>
                        ))}
                    </form>
                    <button className="ticket-button" onClick={() => setIsModalOpen(false)}>{t("newTicket.ok")}</button>
                </Modal>

                <Button type="submit" variant="contained" color="primary">
                    {t("newTicket.add")}
                </Button>
                {errMsg && <Error message={errMsg} onClose={() => setErrMsg('')} />}
                {succ && <Success message={succ} onClose={() => setSucc('')} />}
            </form>
        </Box>
    );
};

export default NewTicket;
