import React, { useEffect, useState } from 'react'
import DisplayForm from '../Table/DisplayForm'
import { getInfoPerTID } from '../../api/axios';
import { useTranslation } from 'react-i18next';

const PerTicketNumber = ({ticket_id}) => {
    const { t } = useTranslation('global');

    const [data, setData] = useState([]);

    const fetchData = async () => {
        try {
            const response = await getInfoPerTID(ticket_id);
            console.log(response);
            setData(response); 
        } catch (error) {
            console.error(t("search.errorFetchingTicketInfo"), error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const keys = [
        'name', 'surname', 'phone', 'create_time', 'remaining_passes', 'remaining_days', 'remaining_daily', 'exceed_date'
    ];
    const labels = {
        name: t("search.name"),
        surname: t("search.lastName"),
        phone: t("search.phoneNumber"),
        create_time: t("search.dateCreated"),
        remaining_passes: t("search.remainingPasses"),
        remaining_days: t("search.remainingDays"),
        remaining_daily: t("search.remainingDailyPasses"),
        exceed_date: t("search.exceedDate")
    };
  return (
    <div>
      <DisplayForm data={data} keys={keys} labels={labels}/>
    </div>
  )
}

export default PerTicketNumber
