import React, { useRef, useEffect, useState, useContext } from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
  Alert,
} from '@mui/material';
import AuthContext from '../../context/AuthProvider';
import { baseURL } from '../../constants/const';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LOGIN_ENDPOINT = baseURL + '/auth';

const Login = () => {
  const { t } = useTranslation('global');
  const { setAuth } = useContext(AuthContext);
  const userRef = useRef();
  const errRef = useRef();
  const navigate = useNavigate();

  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg('');
  }, [user, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new URLSearchParams();
    formData.append('user', user);
    formData.append('pwd', pwd);

    try {
      const response = await axios.post(LOGIN_ENDPOINT, formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      const accessToken = response?.data?.accessToken;
      setAuth({ user, accessToken });
      localStorage.setItem('accessToken', accessToken);
      setUser('');
      setPwd('');
      setSuccess(true);
      navigate('/');
    } catch (error) {
      if (!error?.response) {
        setErrMsg(t('login.noServerResponse') + error.message);
      } else if (error.response?.status === 400) {
        setErrMsg(t('login.wrongUsernameOrPassword'));
      } else if (error.response?.status === 401) {
        setErrMsg(t('login.unauthorized'));
      } else {
        setErrMsg(t('login.loginFailed'));
      }
      errRef.current.focus();
    }
  };

  return (
    <>
      {success ? (
        <Container sx={{ textAlign: 'center', mt: 4 }}>
          <Typography variant="h4">{t('login.youAreLoggedIn')}</Typography>
          <Button href="/" variant="contained" sx={{ mt: 2 }}>
            {t('login.goToHome')}
          </Button>
        </Container>
      ) : (
        <Container maxWidth="xs" sx={{ mt: 4 }}>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              backgroundColor: '#f9f9f9',
              padding: 4,
              borderRadius: 2,
              boxShadow: 1,
            }}
          >
            {errMsg && (
              <Alert severity="error" ref={errRef}>
                {errMsg}
              </Alert>
            )}
            <Typography variant="h4" sx={{ textAlign: 'center' }}>
              {t('login.login')}
            </Typography>
            <FormControl variant="outlined">
              <InputLabel htmlFor="username">{t('login.username')}</InputLabel>
              <OutlinedInput
                id="username"
                type="text"
                inputRef={userRef}
                autoComplete="off"
                value={user}
                onChange={(e) => setUser(e.target.value)}
                label={t('login.username')}
                required
              />
            </FormControl>
            <FormControl variant="outlined">
              <InputLabel htmlFor="password">{t('login.password')}</InputLabel>
              <OutlinedInput
                id="password"
                type="password"
                autoComplete="off"
                value={pwd}
                onChange={(e) => setPwd(e.target.value)}
                label={t('login.password')}
                required
              />
            </FormControl>
            <Button type="submit" variant="contained" fullWidth>
              {t('login.login')}
            </Button>
          </Box>
        </Container>
      )}
    </>
  );
};

export default Login;
