import React, { useState, useEffect } from 'react';
import './table.css';
import CSV from '../CSV/CSV';
import { useTranslation } from 'react-i18next';

const Table = ({ data, column }) => {
    const { t } = useTranslation('global');

    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 15;
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filteredData, setFilteredData] = useState(data);
    const [empty, setEmpty] = useState(false);

    useEffect(() => {
        if (!Array.isArray(data)) {
            console.error(t("table.dataNotAnArray"), data);
            return;
        }
        if (data.length === 0) {
            // Handle case when data is empty
            console.log(t("table.dataIsEmpty"));
            setEmpty(true);
            return;
        }
        const filterData = () => {
            const lowercasedSearchTerm = searchTerm.toLowerCase();
            const filtered = data.filter((item) => {
                const itemDate = new Date(item.date); // Adjust to your data structure
                const isWithinDateRange = startDate && endDate
                    ? itemDate >= new Date(startDate) && itemDate <= new Date(endDate)
                    : true;
                const matchesSearchTerm = Object.values(item)
                    .join('')
                    .toLowerCase()
                    .includes(lowercasedSearchTerm);
                return isWithinDateRange && matchesSearchTerm;
            });
            setFilteredData(filtered);
            setCurrentPage(1); // Reset current page to 1 when data is filtered
        };

        filterData();
    }, [data, searchTerm, startDate, endDate]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < Math.ceil(data.length / rowsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleDateRangeChange = (start, end) => {
        setStartDate(start);
        setEndDate(end);
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // Resetujemo stranicu na prvu kada se promijeni termin pretrage
    };

    const sumPrice = () => {
        if (!Array.isArray(filteredData) || filteredData.length === 0) {
            return 0; // Vrati 0 ako filteredData nije niz ili ako je prazan
        }
    
        return filteredData.reduce((sum, item) => {
            return sum + (parseFloat(item.price) || 0);
        }, 0);
    };
    
    const sumData = () => {
        return filteredData.length;
    };

    const sumColumns = () => {
        const sums = {};
        column.forEach(col => {
            if (col.value === 'price') {
                sums[col.value] = filteredData.reduce((sum, item) => {
                    return sum + (parseFloat(item[col.value]) || 0);
                }, 0);
            } else {
                sums[col.value] = '';
            }
        });
        return sums;
    };

    const sums = sumColumns();

    const paginatedData = Array.isArray(filteredData) ? filteredData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage): [];
    const totalPages = filteredData.length != 0 ? Math.ceil(filteredData.length / rowsPerPage) : '1';

    return (

        <div className='table-container'>

            <div className='table'>
                <input className='search-input-table' type="text" placeholder={t("table.search")} value={searchTerm} onChange={handleSearch} /><br />
                <div className='sum-container'>
                <CSV data={filteredData} fileName={"download"} />
                    <h3>{t("table.totalPrice")} {sumPrice()}</h3>
                    <h3>{t("table.totalData")} {sumData()}</h3>
                </div>
                <table>
                    <thead>
                        <tr>
                            {column.map((item, index) => <TableHeadItem key={index} item={item} />)}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {column.map((col, index) => (
                                <td key={index}>{sums[col.value] ? sums[col.value].toFixed(2) : '-'}</td>
                            ))}
                        </tr>
                        {Array.isArray(paginatedData) && paginatedData.map((item, index) => <TableBodyItem key={index} item={item} column={column} />)}
                    </tbody>
                </table>
                <div className="pagination">
                    <button onClick={handlePrevPage} disabled={currentPage === 1}>
                        &laquo;
                    </button>
                    <span>{currentPage} / {totalPages}</span>
                    <input
                        type="text"
                        min="1"
                        max={totalPages}
                        value={currentPage}
                        onChange={(event) => setCurrentPage(Number(event.target.value))}
                    />
                    <button onClick={handleNextPage} disabled={currentPage === Math.ceil(filteredData.length / rowsPerPage)}>
                        &raquo;
                    </button>
                </div>
            </div>
        </div>
    );
};

const TableHeadItem = ({ item }) => <th>{item.heading}</th>;

const TableBodyItem = ({ item, column }) => (
    <tr>
        {column.map((columnItem, index) => (
            <td key={index}>{item[`${columnItem.value}`]}</td>
        ))}
    </tr>
);

export default Table;
