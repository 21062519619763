import React from 'react';
import { Link } from 'react-router-dom';
import './unauthorized.css'; // Importujte CSS datoteku ako želite stilizirati komponentu
import { useTranslation } from 'react-i18next';

const Unauthorized = () => {
    const { t } = useTranslation('global');

    return (
        <div className="unauthorized-container">
            <h1>{t("error.unauthorized")}</h1>
            <p>{t("error.youHaveNoAccessRightsForThisPage")}</p>
            <Link to="/">{t("error.backToHomepage")}</Link>
        </div>
    );
};

export default Unauthorized;
