import React, {useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom';
import {getNonActiveTickets} from '../../api/axios'
import Table from '../Table/Table';
import { useTranslation } from 'react-i18next';

const NonactiveTickets = () => {
  const { t } = useTranslation('global');

  const [loading, setLoading] = useState(true);
  const [nonactiveTickets, setNonactiveTickets] = useState([]);
  const location = useLocation();
  const { startDate, endDate } = location.state || {};
  useEffect(() => {
    const retreiveInfo = async () => {
        const response = await getNonActiveTickets(startDate, endDate);
        setNonactiveTickets(response);
        setLoading(false);
    };

    retreiveInfo();
  }, []);

  const column = [
    {heading: t("reports.ticket"), value: "ticket_id"},
    {heading: t("reports.dateCreated"), value: "create_time"},
    {heading: t("reports.from"), value: "h_from"},
    {heading: t("reports.until"), value: "h_to"},
    {heading: t("reports.numberOfDays"), value: "day_no"},
    {heading: t("reports.entryNumber"), value: "entry_no"},
    {heading: t("reports.dailyNumberOfEntries"), value: "daily_no"},
  ];

  return (
    <div>
      <h2>{t("reports.inactiveTickets")}</h2>
      { loading ? (<p>{t("reports.loading")}</p>) : (
        <Table data={nonactiveTickets} column={column}/>
        
      ) }
    </div>
  )
}

export default NonactiveTickets
