import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import Table from '../Table/Table';
import { getTypeTickets } from '../../api/axios';
import { useTranslation } from 'react-i18next';

const TypeTickets = () => {
  const { t } = useTranslation('global');

  const [loading, setLoading] = useState(true);
  const [typeTickets, setTypeTickets] = useState([]);
  const location = useLocation();
  const { startDate, endDate, selectedOption } = location.state || {};

  useEffect(() => {
    const retreiveInfo = async () => {
        const response = await getTypeTickets(startDate, endDate, selectedOption);
        setTypeTickets(response);
        setLoading(false);
    };

    retreiveInfo();
  }, []);

  const column = [
    {heading: t("reports.category"), value: "category"},
    {heading: t("reports.type"), value: "type"},
    {heading: t("reports.kind"), value: "kind"},
    {heading: t("reports.price"), value: "price"},
    {heading: t("reports.from"), value: "time_from"},
    {heading: t("reports.until"), value: "time_to"},
    {heading: t("reports.fromDate"), value: "date_from"},
    {heading: t("reports.untilDate"), value: "date_to"},
    {heading: t("reports.dateCreated"), value: "date"},
    {heading: t("reports.timeCreated"), value: "time"},
    {heading: t("reports.PDV"), value: "vat"},
    {heading: t("reports.ticketAgent"), value: "log_card"},
    {heading: t("reports.paymentMethod"), value: "payment_method"},
    {heading: t("reports.QR"), value: "qr_code"},
  ];

  return (
    <div>
      <h2>{selectedOption}</h2>
      { loading ? (<p>{t("reports.loading")}</p>) : (
        <Table data={typeTickets} column={column}/>
        
      ) }
    </div>
  )
}

export default TypeTickets
