import React from 'react';
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem, Box } from '@mui/material';
import { hasPermission } from '../../context/AuthFunc';
import { PERMISSIONS } from '../../constants/const';
import { useTranslation } from 'react-i18next';
import Logout from '../Logout/Logout';
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch';
import DownloadApp from '../DownloadAPP/DownloadAPP';

const Navbar = ({ isLoggedIn, setIsLoggedIn }) => {
  const { t } = useTranslation('global');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {/* Navbar */}
      <AppBar position="fixed" color="primary">
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box>
            <Button color="inherit" href="/">
              {t("navbar.home")}
            </Button>
            {hasPermission(PERMISSIONS.CREATE_NEW_READER) && (
              <>
                <Button color="inherit" onClick={handleMenuOpen}>
                  {t("navbar.readers")}
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleMenuClose} component="a" href="/new-reader">
                    {t("navbar.addReader")}
                  </MenuItem>
                  <MenuItem onClick={handleMenuClose} component="a" href="/readers">
                    {t("navbar.edit/delete")}
                  </MenuItem>
                </Menu>
              </>
            )}
            {hasPermission(PERMISSIONS.CREATE_NEW_TICKET) && (
              <Button color="inherit" href="/new-ticket">
                {t("navbar.addTicket")}
              </Button>
            )}
            {hasPermission(PERMISSIONS.CREATE_NEW_USER) && (
              <Button color="inherit" href="/new-user">
                {t("navbar.addUser")}
              </Button>
            )}
            {hasPermission(PERMISSIONS.VIEW_MONITORING) && (
              <Button color="inherit" href="/monitoring">
                {t("navbar.monitor")}
              </Button>
            )}
            {hasPermission(PERMISSIONS.VIEW_ACCESS_RIGHTS) && (
              <Button color="inherit" href="/access">
                {t("navbar.rights")}
              </Button>
            )}
          </Box>
          <Box display="flex" alignItems="center">
            <Box>
              <DownloadApp />
            </Box>
            <LanguageSwitch />
            <Logout isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
          </Box>
        </Toolbar>
      </AppBar>

      {/* Spacer to prevent content overlap */}
      <Toolbar />
    </>
  );
};

export default Navbar;
