import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
  Alert,
  Snackbar,
} from '@mui/material';
import { getReaderIdExist, postRequest } from '../../api/axios';
import { useTranslation } from 'react-i18next';

const NewReader = () => {
  const { t } = useTranslation('global');

  const [id, setId] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [name, setName] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [idError, setIdError] = useState('');
  const [succ, setSucc] = useState('');

  const checkIdExists = async () => {
    if (id) {
      try {
        const response = await getReaderIdExist(id);
        if (response.success) {
          setIdError(t('newReader.existingReader'));
          return false;
        } else {
          setIdError('');
          return true;
        }
      } catch (error) {
        console.error(t('newReader.errorCheckingId'), error);
        setIdError(t('newReader.errorCheckingReader'));
        return false;
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (id === '') {
      setErrMsg(t('newReader.idRequired'));
      return;
    }
    const userExists = await checkIdExists();
    if (!userExists) {
      return;
    }
    // Slanje backendu
    const formData = new URLSearchParams();
    formData.append('id', id);
    formData.append('serial_num', serialNumber);
    formData.append('name', name);
    formData.append('active', isActive);
    const response = await postRequest('/add-readers', formData);
    if (!response.success) {
      setErrMsg(t('newReader.somethingWentWrong'));
    } else {
      setIdError('');
      setId('');
      setSerialNumber('');
      setName('');
      setIsActive(false);
      setSucc(t('newReader.successfullyAdded'));
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        {t('newReader.newReader')}
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          p: 3,
          backgroundColor: '#f9f9f9',
          borderRadius: 2,
          boxShadow: 1,
        }}
      >
        <TextField
          id="id"
          label={t('newReader.id')}
          variant="outlined"
          value={id}
          onChange={(e) => setId(e.target.value)}
          error={Boolean(idError)}
          helperText={idError}
          fullWidth
          required
        />
        <TextField
          id="serialNumber"
          label={t('newReader.serialNumber')}
          variant="outlined"
          value={serialNumber}
          onChange={(e) => setSerialNumber(e.target.value)}
          fullWidth
        />
        <TextField
          id="name"
          label={t('newReader.name')}
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
        />
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                id="isActive"
                checked={isActive}
                onChange={(e) => setIsActive(e.target.checked)}
              />
            }
            label={t('newReader.active')}
          />
        </FormControl>
        <Button type="submit" variant="contained" fullWidth>
          {t('newReader.add')}
        </Button>
      </Box>
      {errMsg && (
        <Snackbar open={Boolean(errMsg)} autoHideDuration={6000} onClose={() => setErrMsg('')}>
          <Alert severity="error" onClose={() => setErrMsg('')}>
            {errMsg}
          </Alert>
        </Snackbar>
      )}
      {succ && (
        <Snackbar open={Boolean(succ)} autoHideDuration={6000} onClose={() => setSucc('')}>
          <Alert severity="success" onClose={() => setSucc('')}>
            {succ}
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default NewReader;
