import React from 'react'
import Notification from '../Notification/Notification'
import './success.css'

const Success = ({message}) => {
  return (
    <div className='success-message'>
      <Notification message={message}/>
    </div>
  )
}

export default Success
