import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { hasPermission } from '../../context/AuthFunc';

const PrivatesRoutes = ({ permission }) => {
  const token = localStorage.getItem('accessToken');
  
  if (!token) {
    return <Navigate to="/login" />;
  }

  if (!hasPermission(permission)) {
    return <Navigate to="/unauthorized" />;
  }

  return <Outlet />;
};

export default PrivatesRoutes;
