import React from 'react'
import './displayForm.css';
import { useTranslation } from 'react-i18next';

const DisplayForm = ({data, keys, labels}) => {
    const { t } = useTranslation('global');


  return (
    <div className="display-form">
            {keys.map((key, index) => (
                <div key={index} className="display-form-group">
                    <label htmlFor={key}>{labels[key]}</label>
                    <input
                        className='display-input'
                        type="text"
                        id={key}
                        name={key}
                        value={data[key] || ''}
                        readOnly
                    />
                </div>
            ))}
        </div>
  )
}

export default DisplayForm
