// src/components/DownloadApp/DownloadApp.js
import React from 'react';
import { downloadTicketsAppURL } from '../../constants/const';
import { IconButton, Tooltip } from '@mui/material';
import { Download as DownloadIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const DownloadApp = () => {
  const { t } = useTranslation('global');

  const handleDownload = () => {
    const appUrl = downloadTicketsAppURL; // Zamenite stvarnim URL-om
    const link = document.createElement('a');
    link.href = appUrl;
    link.download = '';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Tooltip title={t("downloadApp.downloadNewVersion")}>
      <IconButton onClick={handleDownload} color="inherit">
        <DownloadIcon />
      </IconButton>
    </Tooltip>
  );
};

export default DownloadApp;

