// src/components/Logout/Logout.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@mui/material';
import { Logout as LogoutIcon } from '@mui/icons-material';

const Logout = ({ isLoggedIn, setIsLoggedIn }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('global');

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    setIsLoggedIn(false);
    navigate('/login');
  };

  return (
    isLoggedIn && (
      <Tooltip title={t("logout.logout")}>
        <IconButton onClick={handleLogout} color="inherit">
          <LogoutIcon />
        </IconButton>
      </Tooltip>
    )
  );
};

export default Logout;

