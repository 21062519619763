import {jwtDecode} from 'jwt-decode';

export const getPermissions = () => {
    const token = localStorage.getItem('accessToken');
    if (token) {
        const decoded = jwtDecode(token);
        return decoded.permissions;
    }
    return null;
};

export const hasPermission = (permission) => {
    const permissions = getPermissions();
    //console.log("Permissions: ", permissions);
    return permissions && permissions.includes(permission);
};
export const getUsername = () => {
    const token = localStorage.getItem('accessToken');
    if (token) {
        const decoded = jwtDecode(token);
        return decoded.user;
    }
    return null;
};
