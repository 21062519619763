import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DateRangePicker from '../Table/DateRangePicker';
import { getReportOptions } from '../../api/axios';
import './reportselection.css';
import DailyReport from '../Reports/DailyReport';
import { useTranslation } from 'react-i18next';
import { Button, Box, TextField, MenuItem, Select, InputLabel, FormControl, Typography } from '@mui/material';

const ReportSelection = () => {
  const { t } = useTranslation('global');

  const [reportType, setReportType] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [dateError, setDateError] = useState('');
  const navigate = useNavigate();
  const [reportCategoryOptions, setReportCategoryOptions] = useState([]);
  const [reportTypeOptions, setReportTypeOptions] = useState([]);
  const [reportKindOptions, setReportKindOptions] = useState([]);
  const [reportLogCardOptions, setReportLogCardOptions] = useState([]);
  const [reportPaymentOptions, setReportPaymentOptions] = useState([]);
  const [category, setCategory] = useState('category');
  const [type, setType] = useState('type');
  const [kind, setKind] = useState('kind');
  const [logCard, setLogCard] = useState('log_card');
  const [paymentMethod, setPaymentMethod] = useState('payment_method');
  const [ticket_id, setTicketID] = useState('');
  // const [, ] = useState('');
  // const [, ] = useState('');
  // const [, ] = useState('');
  // const [, ] = useState('');

  useEffect(() => {

    const fetchReportOptions = async () => {
      try {

        const responses = await getReportOptions(category);
        const categoryOptions = responses;
        //console.log("CATEGORY OPTIONS: " + categoryOptions);
        setReportCategoryOptions(categoryOptions);

        const responses1 = await getReportOptions(type);
        const typeOptions = responses1;
        //console.log("TYPE OPTIONS: " + typeOptions);
        setReportTypeOptions(typeOptions);

        const responses2 = await getReportOptions(kind);
        const kindOptions = responses2;
        //console.log("KIND OPTIONS: " + kindOptions);
        setReportKindOptions(kindOptions);

        const responses3 = await getReportOptions(logCard);
        const logOptions = responses3;
        //console.log("LOG OPTIONS: " + logOptions);
        setReportLogCardOptions(logOptions);

        const responses4 = await getReportOptions(paymentMethod);
        const paymentOptions = responses4;
        //console.log("PAYMENT OPTIONS: " + paymentOptions);
        setReportPaymentOptions(paymentOptions);

      } catch (error) {
        console.error(t("reportSelection.errorFetchingReportOptions"), error);
      }
    };

    fetchReportOptions();
  }, []);

  const handleDateRangeChange = (start, end, error) => {
    setStartDate(start);
    setEndDate(end);
    setDateError(error);
  };

  const handleReportChange = (event) => {
    const [route, option] = event.target.value.split('-');
    setReportType(route);
    setSelectedOption(option);
    //console.log(reportType, selectedOption);
  };

  useEffect(() => {
    if (reportType && startDate && endDate && !dateError) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  }, [reportType, startDate, endDate, dateError]);

  const handleSubmit = () => {
    if (reportType && startDate && endDate) {
      navigate(`/${reportType}`, { state: { startDate, endDate, selectedOption } });
    } else {
      alert(t("reportSelection.pleaseSelectAReportAndDateRange"));
    }
  };

  const handleIDSearch = () => {
    if (ticket_id) {
      const newTabUrl = `/ticket-info?ticket_id=${ticket_id}`;
      window.open(newTabUrl, '_blank');
    }
  };
  const handleEditTicket = () => {
    if (ticket_id) {
      navigate(`/ticket/edit/${ticket_id}`);
    }
  };

  return (
  <Box className="container" p={2}>
    {/* Part 1 */}
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, alignItems: "center" }}>
    <Typography variant="h6">{t("reportSelection.searchByTicketNumber")}</Typography>
  <TextField
    id="ticketId"
    variant="outlined"
    placeholder={t("reportSelection.ticketNumber")}
    value={ticket_id}
    onChange={(e) => setTicketID(e.target.value)}
  />
  <Button
    variant="contained"
    color="primary"
    onClick={handleIDSearch}
  >
    {t("reportSelection.search")}
  </Button>

  <Typography variant="h6">{t("reportSelection.changeTicketData")}</Typography>
  <TextField
    id="ticketId"
    variant="outlined"
    placeholder={t("reportSelection.ticketNumber")}
    value={ticket_id}
    onChange={(e) => setTicketID(e.target.value)}
  />
  <Button
    variant="contained"
    color="primary"
    onClick={handleEditTicket}
  >
    {t("reportSelection.search")}
  </Button>
</Box>


    {/* Part 2 */}
    <Box
  className="part2"
  mt={4}
  sx={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 3, // Razmak između elemenata (24px)
  }}
>
  <Typography variant="h6">{t("reportSelection.selectReportAndDate")}</Typography>

  <FormControl fullWidth variant="outlined" sx={{ maxWidth: "400px" }}>
    <InputLabel>{t("reportSelection.selectReport")}</InputLabel>
    <Select
      label={t("reportSelection.selectReport")}
      onChange={handleReportChange}
    >
      <MenuItem value="">{t("reportSelection.selectReport")}</MenuItem>
      <MenuItem value="dailyrep">{t("reportSelection.dailyReport")}</MenuItem>
      <MenuItem value="active">{t("reportSelection.activeTickets")}</MenuItem>
      <MenuItem value="passed">{t("reportSelection.passedTickets")}</MenuItem>
      <MenuItem value="succ_passed">{t("reportSelection.successfullyPassedTickets")}</MenuItem>
      <MenuItem value="nonactive">{t("reportSelection.inactiveTickets")}</MenuItem>
      <MenuItem value="sold">{t("reportSelection.soldTickets")}</MenuItem>
      <optgroup label={t("reportSelection.category")}>
        {Array.isArray(reportCategoryOptions) &&
          reportCategoryOptions.map((option, index) => (
            <MenuItem key={option + `_${index}`} value={category + `-${option}`}>
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </MenuItem>
          ))}
      </optgroup>
      <optgroup label={t("reportSelection.type")}>
        {Array.isArray(reportTypeOptions) &&
          reportTypeOptions.map((option, index) => (
            <MenuItem key={option + `_${index}`} value={type + `-${option}`}>
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </MenuItem>
          ))}
      </optgroup>
    </Select>
  </FormControl>

  <Box sx={{ width: "100%", maxWidth: "400px" }}>
    <DateRangePicker onDateRangeChange={handleDateRangeChange} />
  </Box>

  <Button
    variant="contained"
    color="primary"
    onClick={handleSubmit}
    disabled={!isButtonEnabled}
  >
    {t("reportSelection.generateReport")}
  </Button>
</Box>


    {/* Part 3 */}
    <Box
  mt={4}
  sx={{
    display: "flex",
    flexDirection: "column", // Organizuje elemente vertikalno
    alignItems: "center", // Centriranje horizontalno
    gap: 2, // Razmak između elemenata (8px * 2 = 16px)
  }}
>
  <Typography variant="h6" title={t("reportSelection.fillAnyField")}>
    {t("reportSelection.searchByUser")}
  </Typography>
  <TextField
    id="name"
    variant="outlined"
    placeholder={t("reportSelection.name")}
    fullWidth
    sx={{ maxWidth: "400px" }} // Ograničenje širine za bolji izgled
  />
  <TextField
    id="surname"
    variant="outlined"
    placeholder={t("reportSelection.lastName")}
    fullWidth
    sx={{ maxWidth: "400px" }}
  />
  <TextField
    id="phone"
    variant="outlined"
    placeholder={t("reportSelection.phoneNumber")}
    fullWidth
    sx={{ maxWidth: "400px" }}
  />
  <Button
    variant="contained"
    color="primary"
    sx={{ width: "40%", mt: 2 }} // Dodaj razmak gore
  >
    {t("reportSelection.search")}
  </Button>
</Box>


    {/* Parts 4-6 */}
    <Box className="part4" mt={2}></Box>
    <Box className="part5" mt={2}></Box>
    <Box className="part6" mt={2}></Box>
  </Box>
);
};

export default ReportSelection;
