import React from 'react'
import Notification from '../Notification/Notification'
import './error.css'

const Error = ({message, onClose}) => {
  return (
    <div className='error-message'>
      <Notification message={message} onClose={onClose}/>
    </div>
  )
}

export default Error
